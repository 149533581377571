/**
 * @fileoverview 目標設定チャットのカスタムフック
 */

import { useState, useEffect, useRef } from 'react';
import { supabase } from '../utils/supabase';
import { goalChatService } from '../services/goalChatService';

export const useGoalChat = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentHistoryId, setCurrentHistoryId] = useState(null);
  const [chatHistories, setChatHistories] = useState([]);
  const [model, setModel] = useState('gpt-4o-mini');
  const shouldScrollRef = useRef(false);
  const [chatTitles, setChatTitles] = useState({});

  useEffect(() => {
    initializeChat();
  }, []);

  const initializeChat = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('ユーザーが見つかりません');

      const histories = await goalChatService.fetchChatHistories(user.id);
      setChatHistories(histories);

      if (histories.length > 0) {
        const firstHistory = histories[0];
        setCurrentHistoryId(firstHistory.id);
        setMessages(firstHistory.messages);
      } else {
        const newHistory = await goalChatService.createChatHistory(user.id);
        setCurrentHistoryId(newHistory.id);
        setChatHistories([newHistory]);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async (content) => {
    if (!content.trim()) return;

    try {
      setLoading(true);
      shouldScrollRef.current = true; // メッセージ送信時にスクロールフラグを立てる

      // ユーザーメッセージを追加
      const userMessage = { content, isUser: true, timestamp: new Date().toISOString() };
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);
      await goalChatService.addMessage(currentHistoryId, userMessage);

      // AIの応答を取得
      const aiResponse = await goalChatService.getAIResponse(updatedMessages, model);
      const aiMessage = { content: aiResponse, isUser: false, timestamp: new Date().toISOString() };
      
      // AIメッセージを追加
      setMessages([...updatedMessages, aiMessage]);
      await goalChatService.addMessage(currentHistoryId, aiMessage);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const changeModel = (newModel) => {
    setModel(newModel);
  };

  const switchChatHistory = async (historyId) => {
    try {
      setLoading(true);
      const history = await goalChatService.fetchChatHistory(historyId);
      setCurrentHistoryId(historyId);
      setMessages(history.messages);
      
      // チャット履歴一覧も更新
      const updatedHistories = chatHistories.map(h => 
        h.id === historyId ? { ...h, ...history } : h
      );
      setChatHistories(updatedHistories);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const createNewChat = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      const newHistory = await goalChatService.createChatHistory(user.id);
      setCurrentHistoryId(newHistory.id);
      setMessages([]);
      setChatHistories(prev => [newHistory, ...prev]);
      
      setChatTitles(prev => ({
        ...prev,
        [newHistory.id]: '新しいチャット'
      }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    messages,
    loading,
    error,
    sendMessage,
    model,
    changeModel,
    setMessages,
    setError,
    setLoading,
    shouldScrollRef,
    chatHistories,
    currentHistoryId,
    switchChatHistory,
    createNewChat,
    chatTitles
  };
};
