/**
 * @fileoverview 目標設定チャットサービス
 */

import { supabase } from '../utils/supabase';
import { openaiService } from './openaiService';
import { PROMPTS } from '../constants/prompts';

export const goalChatService = {
  /**
   * チャット履歴を取得する
   * @param {string} userId - ユーザーID
   * @returns {Promise<Array>} チャット履歴の配列
   */
  async fetchChatHistories(userId) {
    const { data, error } = await supabase
      .from('goal_chat_histories')
      .select('id, user_id, messages, created_at, updated_at, title, last_title_update')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    
    return data.map(history => ({
      ...history,
      title: history.title || '無題のチャット'
    }));
  },

  /**
   * 新しいチャット履歴を作成する
   * @param {string} userId - ユーザーID
   * @returns {Promise<Object>} 作成されたチャット履歴
   */
  async createChatHistory(userId) {
    const { data, error } = await supabase
      .from('goal_chat_histories')
      .insert([{ user_id: userId, messages: [] }])
      .select('id, user_id, messages, created_at, updated_at')
      .single();

    if (error) throw error;
    return data;
  },

  /**
   * チャット履歴にメッセージを追加する
   * @param {string} historyId - チャット履歴ID
   * @param {Object} message - 追加するメッセージ
   * @returns {Promise<Object>} 更新されたチャット履歴
   */
  async addMessage(historyId, message) {
    const { data: currentHistory, error: fetchError } = await supabase
      .from('goal_chat_histories')
      .select('messages')
      .eq('id', historyId)
      .single();

    if (fetchError) throw fetchError;

    const updatedMessages = [...currentHistory.messages, message];

    const { data, error: updateError } = await supabase
      .from('goal_chat_histories')
      .update({ 
        messages: updatedMessages,
        updated_at: new Date().toISOString()
      })
      .eq('id', historyId)
      .select('id, user_id, messages, created_at, updated_at')
      .single();

    if (updateError) throw updateError;
    return data;
  },

  /**
   * AIからの応答を取得する
   * @param {Array} messages - これまでのメッセージ履歴
   * @param {string} model - 使用するAIモデル
   * @returns {Promise<string>} AIからの応答
   */
  async getAIResponse(messages, model) {
    const formattedMessages = [
      { role: 'system', content: PROMPTS.GOAL_CHAT.SYSTEM },
      ...messages.map(msg => ({
        role: msg.isUser ? 'user' : 'assistant',
        content: msg.content
      }))
    ];

    return await openaiService.getChatCompletion(formattedMessages, model);
  },

  /**
   * 目標提案のタイミングかどうかを判断する
   * @param {Array} messages - メッセージ履歴
   * @returns {Promise<boolean>} 目標提案を行うべきかどうか
   */
  async shouldProposeGoal(messages) {
    const messageHistory = messages.map(m => ({
      role: m.isUser ? 'user' : 'assistant',
      content: m.content
    }));

    const response = await openaiService.getChatCompletion([
      { role: 'system', content: PROMPTS.GOAL_PROPOSAL_CHECK.SYSTEM },
      ...messageHistory
    ], 'gpt-4o-mini');

    return response.toLowerCase().includes('true');
  },

  async fetchChatHistory(historyId) {
    const { data, error } = await supabase
      .from('goal_chat_histories')
      .select('*')
      .eq('id', historyId)
      .single();

    if (error) throw error;
    
    return {
      ...data,
      title: data.title || '無題のチャット'
    };
  },

  /**
   * チャット履歴を更新する
   * @param {string} historyId - チャット履歴ID
   * @param {Array} messages - メッセージ配列
   * @returns {Promise<Object>} 更新されたチャット履歴
   */
  async updateChatHistory(historyId, messages) {
    try {
      const { data: currentHistory } = await supabase
        .from('goal_chat_histories')
        .select('title, messages, last_title_update')
        .eq('id', historyId)
        .single();

      let title = currentHistory.title;
      // タイトルが未設定か、メッセージが2件以上ある場合にタイトルを生成
      if (!title && messages.length >= 2) {
        title = await openaiService.generateChatTitle(messages);
      }

      const { data, error } = await supabase
        .from('goal_chat_histories')
        .update({ 
          messages,
          title,
          last_title_update: new Date().toISOString(),
          updated_at: new Date().toISOString()
        })
        .eq('id', historyId)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('チャット履歴の更新に失敗しました:', error);
      throw error;
    }
  },
};
