/**
 * @fileoverview 目標設定チャットボックスコンポーネント
 */

import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  IconButton,
  Tooltip,
  Button,
  Fade,
  List,
  ListItem,
  ListItemText,
  Drawer
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ReactMarkdown from 'react-markdown';
import { useGoalChat } from '../../hooks/useGoalChat';
import { goalService } from '../../services/goalService';
import { openaiService } from '../../services/openaiService';
import { supabase } from '../../utils/supabase';
import { goalChatService } from '../../services/goalChatService';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { keyframes } from '@mui/system';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';

const GoalChatBox = ({ onShowGoalDialog }) => {
  const [input, setInput] = useState('');
  const [localError, setLocalError] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const messagesEndRef = useRef(null);
  const [showGoalConfirmation, setShowGoalConfirmation] = useState(false);
  const [proposedGoals, setProposedGoals] = useState(null);
  const [canGenerateGoal, setCanGenerateGoal] = useState(false);
  const [isComposing, setIsComposing] = useState(false); // IME変換状態を管理
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [chatTitles, setChatTitles] = useState({});

  const { 
    messages, 
    loading, 
    error: chatError, 
    sendMessage, 
    model, 
    changeModel,
    setMessages,
    setError,
    setLoading,
    shouldScrollRef,
    chatHistories,
    currentHistoryId,
    switchChatHistory,
    createNewChat
  } = useGoalChat();

  // キラキラエフェクトのアニメーション
  const sparkle = keyframes`
    0% { transform: scale(1); opacity: 0.5; }
    50% { transform: scale(1.1); opacity: 1; }
    100% { transform: scale(1); opacity: 0.5; }
  `;

  // 目標生成ボタンのスタイル
  const generateButtonStyle = (canGenerate) => ({
    position: 'absolute',
    right: '16px',
    top: '16px',
    zIndex: 1,
    bgcolor: canGenerate ? 'primary.main' : 'grey.300',
    color: canGenerate ? 'white' : 'text.secondary',
    '&:hover': {
      bgcolor: canGenerate ? 'primary.dark' : 'grey.400',
    },
    animation: canGenerate ? `${sparkle} 2s infinite` : 'none',
    transition: 'all 0.3s ease-in-out',
    boxShadow: canGenerate ? 4 : 0,
  });

  const scrollToBottom = () => {
    if (shouldScrollRef.current && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      shouldScrollRef.current = false; // スクロール後にフラグをリセット
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // 目標生成の準備状態をチェック
  useEffect(() => {
    const checkGoalReadiness = async () => {
      // メッセージが1つ以上あれば生成可能とする
      setCanGenerateGoal(messages.length > 0);
    };
    checkGoalReadiness();
  }, [messages]);

  const handleGenerateGoals = async (proposal) => {
    try {
      setIsGenerating(true);
      const { data: { user } } = await supabase.auth.getUser();
      
      // メイン目標を作成
      const mainGoalData = {
        user_id: user.id,
        title: proposal.mainGoal.title,
        description: proposal.mainGoal.description,
        deadline: proposal.mainGoal.deadline,
        target_value: proposal.mainGoal.targetValue,
        unit_id: proposal.mainGoal.unitName,
        status: 'not_started',
        project_id: null // 必要に応じてプロジェクトIDを設定
      };

      const mainGoal = await goalService.createGoal(mainGoalData);

      // サブ目標を作成
      const subGoalPromises = proposal.mainGoal.subGoals.map(subGoal => 
        goalService.createGoal({
          ...subGoal,
          user_id: user.id,
          parent_goal_id: mainGoal.id,
          status: 'not_started'
        })
      );

      await Promise.all(subGoalPromises);

      // 成功メッセージを表示
      const successMessage = {
        content: `目標の作成が完了しました！\n\n作成された目標：\n# ${mainGoal.title}\n\nダッシュボードで確認してみましょう。`,
        isUser: false,
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, successMessage]);

    } catch (error) {
      console.error('目標の作成に失敗しました:', error);
      setLocalError('目標の作成に失敗しました。');
    } finally {
      setIsGenerating(false);
      setShowGoalConfirmation(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || loading || isGenerating) return;

    try {
      setLoading(true);
      setLocalError(null);
      const userMessage = { content: input, isUser: true, timestamp: new Date().toISOString() };
      const updatedMessages = [...messages, userMessage];
      
      // メッセージを送信時に保存
      await goalChatService.updateChatHistory(currentHistoryId, updatedMessages);
      setMessages(updatedMessages);

      // 入力フィールドをクリア
      setInput('');

      // AIの応答を取得
      const aiResponse = await goalChatService.getAIResponse(updatedMessages, model);
      const aiMessage = { content: aiResponse, isUser: false, timestamp: new Date().toISOString() };

      // AI応答も保存
      const finalMessages = [...updatedMessages, aiMessage];
      await goalChatService.updateChatHistory(currentHistoryId, finalMessages);
      setMessages(finalMessages);
      
    } catch (error) {
      console.error('Error:', error);
      setLocalError('メッセージの送信に失敗しました。');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    // IME変換中またはShiftキーが押されている場合は送信しない
    if (e.key === 'Enter' && !e.shiftKey && !isComposing) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  // 目標生成ボタンのクリックハンドラ
  const handleGenerateClick = async () => {
    if (isGenerating || messages.length === 0) return;

    try {
      setIsGenerating(true);
      setLocalError(null);

      // ユーザーIDを取得
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('ユーザーが見つかりません');
      }

      const proposal = await openaiService.generateGoalProposal(messages, model);
      
      // メイン目標のデータを準備
      const mainGoalData = {
        title: proposal.mainGoal.title || '新しい目標',
        description: proposal.mainGoal.description || '',
        deadline: proposal.mainGoal.deadline || null,
        target_value: proposal.mainGoal.targetValue || null,
        unit_id: proposal.mainGoal.unitId || null,
        status: 'not_started',
        reference_links: [],
        user_id: user.id  // ユーザーIDを設定
      };

      // サブ目標のデータを整形
      const formattedSubGoals = (proposal.mainGoal.subGoals || []).map(sub => ({
        title: sub.title || '',
        description: sub.description || '',
        deadline: sub.deadline || null,
        target_value: sub.targetValue || null,
        unit_id: sub.unitId || null,
        status: 'not_started',
        is_completed: false, // デフォルト値を明示的に設定
        reference_links: [],
        user_id: user.id
      }));

      // 確認メッセージをチャットに追加
      const aiMessage = {
        content: `
目標を生成しました：

# ${mainGoalData.title}
${mainGoalData.description}

## サブ目標：
${formattedSubGoals.map(sub => `- ${sub.title}`).join('\n')}

目標の詳細を確認・編集できる画面を表示します。`,
        isUser: false,
        timestamp: new Date().toISOString()
      };
      
      setMessages(prev => [...prev, aiMessage]);

      // GoalDialogを表示（サブ目標データを含めて渡す）
      onShowGoalDialog({
        ...mainGoalData,
        subGoals: formattedSubGoals
      });

    } catch (error) {
      console.error('目標提案の生成に失敗しました:', error);
      setLocalError('目標の生成に失敗しました。もう一度お試しください。チャットでもう少し具体的な情報を入力すると、より良い目標が生成できます。');
    } finally {
      setIsGenerating(false);
    }
  };

  // プロジェクト選択後の目標保存処理を追加
  const handleSaveGoals = async (proposal, projectId) => {
    try {
      // メイン目標の保存
      const mainGoal = await goalService.createGoal({
        title: proposal.mainGoal.title,
        description: proposal.mainGoal.description,
        project_id: projectId,
        deadline: proposal.mainGoal.deadline,
        target_value: proposal.mainGoal.targetValue,
        unit_id: proposal.mainGoal.unitId,
        status: 'not_started'
      });

      // サブ目標の保存
      for (const subGoal of proposal.mainGoal.subGoals) {
        await goalService.createGoal({
          title: subGoal.title,
          description: subGoal.description,
          project_id: projectId,
          parent_goal_id: mainGoal.id,
          deadline: subGoal.deadline,
          target_value: subGoal.targetValue,
          unit_id: subGoal.unitId,
          status: 'not_started'
        });
      }

      const successMessage = {
        content: '目標を保存しました！',
        isUser: false,
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, successMessage]);

    } catch (error) {
      console.error('目標の保存に失敗しました:', error);
      setLocalError('目標の保存に失敗しました。');
    }
  };

  const handleHistoryClick = (historyId) => {
    switchChatHistory(historyId);
    setIsHistoryDrawerOpen(false);
  };

  const handleNewChat = () => {
    createNewChat();
    setIsHistoryDrawerOpen(false);
  };

  const messageStyle = (isUser) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: 2,
    mb: 2,
    flexDirection: isUser ? 'row-reverse' : 'row',
  });

  const bubbleStyle = (isUser) => ({
    maxWidth: '70%',
    p: 2,
    borderRadius: 2,
    bgcolor: isUser ? 'primary.main' : 'grey.100',
    color: isUser ? 'white' : 'text.primary',
    '& p': { m: 0 },
  });

  const renderGoalConfirmation = () => {
    if (!showGoalConfirmation || !proposedGoals) return null;

    return (
      <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1, border: 1, borderColor: 'divider' }}>
        <Typography variant="h6" gutterBottom>
          目標の確認
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          下の目標を作成します：
        </Typography>
        <Box sx={{ ml: 2, mb: 2 }}>
          <Typography variant="h6">{proposedGoals.mainGoal.title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {proposedGoals.mainGoal.description}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>サブ目標：</Typography>
          <ul>
            {proposedGoals.mainGoal.subGoals.map((sub, index) => (
              <li key={index}>
                <Typography>{sub.title}</Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            onClick={() => setShowGoalConfirmation(false)}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={() => handleGenerateGoals(proposedGoals)}
            disabled={isGenerating}
          >
            {isGenerating ? <CircularProgress size={24} /> : '目標を作成'}
          </Button>
        </Box>
      </Box>
    );
  };

  const handleSaveChat = async () => {
    if (isSaving || !currentHistoryId) return;

    try {
      setIsSaving(true);
      await goalChatService.updateChatHistory(currentHistoryId, messages);
      // 成功メッセージを表示
      console.log('チャット履歴を保存しました');
    } catch (error) {
      console.error('チャット履歴の保存に失敗しました:', error);
      setError('チャット履歴の保存に失敗しました');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    // チャット履歴のタイトルを生成
    const generateChatTitles = async () => {
      const titles = {};
      for (const history of chatHistories) {
        if (history.messages.length > 0) {
          const title = await generateChatTitle(history.messages);
          titles[history.id] = title;
        }
      }
      setChatTitles(titles);
    };

    generateChatTitles();
  }, [chatHistories]);

  const generateChatTitle = async (messages) => {
    try {
      const prompt = `以下のチャットの内容を要約して、30文字以内のタイトルを生成してください：\n\n${messages.map(m => m.content).join('\n')}`;
      const title = await openaiService.generateChatTitle(prompt);
      return title;
    } catch (error) {
      console.error('チャットタイトルの生成に失敗しました:', error);
      return '無題のチャット';
    }
  };

  return (
    <Box sx={{ 
      height: '600px', 
      display: 'flex', 
      flexDirection: 'column',
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 1,
      bgcolor: 'background.paper'
    }}>
      {/* ヘッダー */}
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Typography variant="h6" component="div" sx={{ mb: 2 }}>
          リフレコと目標を相談
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              variant="contained"
              onClick={handleGenerateClick}
              disabled={loading || isGenerating || messages.length === 0}
              startIcon={loading || isGenerating ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <AutoAwesomeIcon />
              )}
            >
              {loading || isGenerating ? '目標生成中...' : '目標を生成'}
            </Button>
            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel>AIモデル</InputLabel>
              <Select
                value={model}
                label="AIモデル"
                onChange={(e) => changeModel(e.target.value)}
                disabled={loading || isGenerating}
              >
                <MenuItem value="gpt-4o-mini">GPT-4o Mini</MenuItem>
                <MenuItem value="gpt-4o">GPT-4o</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Tooltip title="チャット履歴">
              <IconButton onClick={() => setIsHistoryDrawerOpen(true)}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="新しいチャット">
              <IconButton onClick={handleNewChat}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="チャットを保存">
              <IconButton onClick={handleSaveChat} disabled={isSaving}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {/* メッセージエリア */}
      <Box sx={{ 
        flex: 1, 
        overflowY: 'auto', 
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}>
        {messages.map((message, index) => (
          <Box key={index} sx={messageStyle(message.isUser)}>
            {!message.isUser && (
              <Avatar
                alt="リフレコ"
                src="/images/refreco-avatar.png"
                sx={{ width: 40, height: 40 }}
              />
            )}
            <Box sx={bubbleStyle(message.isUser)}>
              <ReactMarkdown>{message.content}</ReactMarkdown>
            </Box>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      {/* 入力エリア */}
      <Box component="form" onSubmit={handleSubmit} sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TextField
            fullWidth
            multiline
            minRows={1}
            maxRows={4}
            size="small"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            onCompositionStart={() => setIsComposing(true)}
            onCompositionEnd={() => setIsComposing(false)}
            placeholder="目標について相談してください... (Shift + Enter で改行)"
            disabled={loading || isGenerating}
            sx={{
              '& .MuiInputBase-root': {
                padding: '8px 14px',
              },
            }}
          />
          <IconButton 
            color="primary" 
            type="submit" 
            disabled={loading || isGenerating || !input.trim()}
          >
            {(loading || isGenerating) ? <CircularProgress size={24} /> : <SendIcon />}
          </IconButton>
        </Box>
        {(localError || chatError) && (
          <Typography color="error" variant="caption" sx={{ mt: 1 }}>
            {localError || chatError}
          </Typography>
        )}
      </Box>
      {renderGoalConfirmation()}

      {/* チャット履歴ドロワー */}
      <Drawer
        anchor="right"
        open={isHistoryDrawerOpen}
        onClose={() => setIsHistoryDrawerOpen(false)}
      >
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            {chatHistories
              .filter(history => history.messages.length > 0)
              .map((history) => (
                <ListItem 
                  button 
                  key={history.id} 
                  onClick={() => handleHistoryClick(history.id)}
                  selected={history.id === currentHistoryId}
                >
                  <ListItemText 
                    primary={history.title || '無題のチャット'}
                    secondary={`${new Date(history.created_at).toLocaleDateString()} - ${history.messages.length}件のメッセージ`}
                  />
                </ListItem>
              ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default GoalChatBox;
