/**
 * @fileoverview 要約生成コンポーネント
 * @description 要約生成のためのUIと機能を提供します
 */

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  CircularProgress, 
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ReactMarkdown from 'react-markdown';
import { useSummary } from '../../hooks/useSummary';

const SummaryGenerator = ({
  title,
  summaryService,
  targetId,
  generateSummary,
  disabled = false,
  defaultInstructions = ''
}) => {
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [model, setModel] = useState('gpt-4o-mini');
  const [userInstructions, setUserInstructions] = useState(defaultInstructions);
  const [selectedSummaryId, setSelectedSummaryId] = useState(null);

  const {
    summaries,
    loading: summariesLoading,
    createSummary
  } = useSummary(targetId, summaryService);

  const handleGenerateSummary = async () => {
    setLoading(true);
    setError(null);
    try {
      const generatedSummary = await generateSummary(model, userInstructions);
      await createSummary(generatedSummary, model, userInstructions); // userInstructionsを追加
      setSummary(generatedSummary);
    } catch (err) {
      setError('要約の生成に失敗しました。しばらく待ってから再度お試しください。');
      console.error('要約生成エラー:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSummaryChange = (summaryId) => {
    const selected = summaries.find(s => s.id === summaryId);
    if (selected) {
      setSummary(selected.content);
      setSelectedSummaryId(summaryId);
      // 選択された要約の指示を設定（存在しない場合はデフォルトの指示を使用）
      setUserInstructions(selected.user_instructions || defaultInstructions);
    }
  };

  // 初期表示時の処理も更新
  useEffect(() => {
    if (summaries.length > 0) {
      const latestSummary = summaries[0];
      setSummary(latestSummary.content);
      setSelectedSummaryId(latestSummary.id);
      setUserInstructions(latestSummary.user_instructions || defaultInstructions);
    } else {
      // 要約がない場合はデフォルトの指示を設定
      setUserInstructions(defaultInstructions);
    }
  }, [summaries, defaultInstructions]);

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* コントロール部分 */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              variant="contained"
              onClick={handleGenerateSummary}
              disabled={loading || disabled}
              startIcon={loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <AutoAwesomeIcon sx={{ 
                  animation: loading ? 'none' : 'sparkle 1.5s ease-in-out infinite'
                }} />
              )}
            >
              {loading ? '要約生成中...' : '要約を生成'}
            </Button>
            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel>AIモデル</InputLabel>
              <Select
                value={model}
                label="AIモデル"
                onChange={(e) => setModel(e.target.value)}
                disabled={loading}
              >
                <MenuItem value="gpt-4o-mini">GPT-4o Mini (推奨)</MenuItem>
                <MenuItem value="gpt-4o">GPT-4o (高性能)</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <FormControl size="small" sx={{ minWidth: 250 }}>
            <InputLabel>要約履歴</InputLabel>
            <Select
              value={selectedSummaryId || ''}
              label="要約履歴"
              onChange={(e) => handleSummaryChange(e.target.value)}
            >
              {summaries.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {new Date(s.created_at).toLocaleString()} ({s.model})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* 追加指示入力フィールド */}
        <TextField
          multiline
          rows={2}
          variant="outlined"
          label="追加の指示（オプション）"
          value={userInstructions}
          onChange={(e) => setUserInstructions(e.target.value)}
          placeholder="要約に関する追加の指示があれば入力してください"
          disabled={loading}
          fullWidth
        />

        {error && (
          <Typography color="error">
            {error}
          </Typography>
        )}

        {/* 要約表示部分 */}
        {summary && (
          <Box sx={{ 
            mt: 2,
            '& p': { mb: 2 },
            '& h2': { 
              fontSize: '1.2rem',
              fontWeight: 'bold',
              mt: 3,
              mb: 1,
              color: 'primary.main'
            },
            '& ul': { pl: 3, mb: 2 },
            '& li': { mb: 0.5 }
          }}>
            <ReactMarkdown>{summary}</ReactMarkdown>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default SummaryGenerator;
